@tailwind base;
@tailwind components;
@tailwind utilities;

.navbar-item {
  @apply p-2 sm:px-10 transition ease-in-out duration-300 hover:bg-red-300;
}

html {
  scroll-behavior: smooth;
}
